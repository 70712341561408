document.addEventListener("DOMContentLoaded", () => {
  function smoothScroll(target) {
    const element = document.getElementById(target);
    const headerHeight = document.querySelector("dea-top-header").offsetHeight;
    if (element) {
      window.scrollTo({
        top: element.offsetTop + headerHeight - 20,
        behavior: "smooth",
      });
    }
  }

  const anchors = document.querySelectorAll('.deaflix-content-button[href^="#"]');

  anchors.forEach(anchor => {
    anchor.addEventListener("click", event => {
      event.preventDefault();
      const target = anchor.getAttribute("href").substring(1);
      smoothScroll(target);
    });
  });
});
